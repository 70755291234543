import I18n from '/src/utils/translations'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import { isPresent, isBlankOrZero } from '/src/utils/boolean_refinements'
import  MetricLean  from '/src/utils/metric_lean'


export const calculateProductivity = (hours, quantity, lean) => {

   if (isPresent(hours) && !isBlankOrZero(quantity)) {
    if (lean === true) {
      return quantity / hours || 0
    } 
    return hours / quantity || 0
  }

  if (lean === true) {
    return quantity / hours || 0
  }

  return 0
}

const calculatePF = ({ actualHours, actualQty, scopedHours, scopedQty }) => {
  const lean = MetricLean()
  const actualProductivity = calculateProductivity(actualHours, actualQty, lean)
  const scopedProductivity = calculateProductivity(scopedHours, scopedQty, lean)

  if (!isBlankOrZero(scopedProductivity)) return (actualProductivity / scopedProductivity)

  return I18n.t('fields.na')
}

//   summary.actual_hours / summary.actual_quantity
// ----------------------------------------------------
//       summary.progress_hours / quantity
export const calculateProgressPF = (progressItem) => {
  const { progress_summary: summary, quantity } = progressItem

  if (!isPresent(summary)) return I18n.t('fields.na')

  const {
    actual_hours: actualHours,
    actual_quantity: actualQty,
    progress_hours: scopedHours
  } = summary 

  return calculatePF({ actualHours, actualQty, scopedHours, scopedQty: quantity })}

//   historyItem.daily_hours / historyItem.daily_quantity
// ----------------------------------------------------
//   progressItem.summary.progress_hours / progressItem.quantity
export const calculateProgressHistoryItemPF = (progressItem, historyItem) => {
  const { progress_summary: summary, quantity } = progressItem
  const { daily_hours: actualHours, daily_quantity: actualQty } = historyItem

  if (!isPresent(summary)) return I18n.t('fields.na')

  const calculatePFProps = {
    actualHours,
    actualQty,
    scopedHours: summary.progress_hours,
    scopedQty: quantity
  }

  return calculatePF(calculatePFProps)
}

//   summary.actual_hours / summary.actual_quantity
// ----------------------------------------------------
//   serviceItem.team_target_hours / serviceItem.quantity
export const calculateServicePF = (serviceItem) => {
  const { progress_service_summary: summary, team_target_hours: tth, quantity } = serviceItem

  if (!isPresent(summary)) return I18n.t('fields.na')

  const { actual_hours: actualHours, actual_quantity: actualQty } = summary

  return calculatePF({ actualHours, actualQty, scopedHours: tth, scopedQty: quantity })
}

//   historyItem.daily_hours / historyItem.daily_quantity
// ----------------------------------------------------
//     serviceItem.team_target_hours / serviceItem.quantity
export const calculateServiceHistoryItemPF = (serviceItem, historyItem) => { 
  const { daily_hours: actualHours, daily_quantity: actualQty } = historyItem
  const { team_target_hours: tth, quantity  } = serviceItem

  return calculatePF({ actualHours, actualQty, scopedHours: tth, scopedQty: quantity })
}

export const formatPF = (pf, project) => {
  if (Number.isNaN(pf)) return pf

  return formatNumberWithPrecision(pf, project)
}





