import { useStore } from 'react-context-hook'

export default function MetricLean() {
    
    const [globalProject] = useStore('project')
   
    const lean = globalProject.metric_lean
    
    return lean
}
